.product-ui-sidebar_container__wBGOb {
    max-inline-size: 15.625rem; /* 250px */
    padding: var(--space-16) var(--space-16) 0 var(--space-12);
    background-color: var(--productui-sidebar);
    block-size: 100%;
    display: flex;
    flex-direction: column;
    border-top-left-radius: var(--border-radius-xl);
    border-bottom-left-radius: var(--border-radius-xl);
    gap: var(--space-24);
}

    .product-ui-sidebar_container__wBGOb .product-ui-sidebar_section__B8QL5 {
        display: flex;
        flex-direction: column;
        inline-size: 100%;
    }

    .product-ui-sidebar_container__wBGOb .product-ui-sidebar_workspace__leUgc {
        gap: var(--space-12);
    }

    .product-ui-sidebar_container__wBGOb .product-ui-sidebar_content__ldElY {
        gap: var(--space-24);
    }

.product-ui-sidebar_profileMenu__iyYcY {
    display: flex;
    justify-content: space-between;
    padding-inline-start: var(--space-4);
}

.product-ui-sidebar_avatarContainer__t_Z4U {
    display: flex;
    flex-basis: 50%;
    gap: 6px;
    align-items: center;
    color: var(--display-onlight-tertiary);
    border-radius: var(--border-radius-sm);
}

.product-ui-sidebar_avatarContainer__t_Z4U.product-ui-sidebar_hovered__PVjla {
        background-color: rgb(250 237 230);
    }

.product-ui-sidebar_profileMenuAvatar__TyedZ {
    display: grid;
    inline-size: 26px;
    block-size: 26px;
    place-items: center;
}

.product-ui-sidebar_profileMenuAvatar__TyedZ > * {
        grid-column: 1;
        grid-row: 1;
    }

.product-ui-sidebar_profileMenuAvatar__TyedZ svg {
        transform-origin: center;
        transform: rotate(-90deg);
    }

.product-ui-sidebar_hovered__PVjla .product-ui-sidebar_menuChevron__ZnJcM {
        color: var(--display-onlight-primary);
    }

.product-ui-sidebar_progressBackground__kVXy1 {
    fill: none;
    stroke: var(--selectable-unselected-background-idle);
    stroke-width: 2px;
}

.product-ui-sidebar_progressArc__C432_ {
    fill: none;
    stroke: var(--productui-primary-theme-color);
    stroke-width: 2px;
    stroke-linecap: round;
}

.product-ui-sidebar_profileIcons__91Lb2 {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--display-onlight-tertiary);
    block-size: var(--space-24);
}

.product-ui-sidebar_profileIcons__91Lb2 > div {
        position: relative;
    }

.product-ui-sidebar_profileIcons__91Lb2 > div > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

.product-ui-sidebar_profileIcons__91Lb2 > div {
        inline-size: var(--space-24);
        block-size: var(--space-24);
}

