.container {
    max-inline-size: 15.625rem; /* 250px */
    padding: var(--space-16) var(--space-16) 0 var(--space-12);
    background-color: var(--productui-sidebar);
    block-size: 100%;
    display: flex;
    flex-direction: column;
    border-top-left-radius: var(--border-radius-xl);
    border-bottom-left-radius: var(--border-radius-xl);
    gap: var(--space-24);

    .section {
        display: flex;
        flex-direction: column;
        inline-size: 100%;
    }

    .workspace {
        gap: var(--space-12);
    }

    .content {
        gap: var(--space-24);
    }
}

.profileMenu {
    display: flex;
    justify-content: space-between;
    padding-inline-start: var(--space-4);
}

.avatarContainer {
    display: flex;
    flex-basis: 50%;
    gap: 6px;
    align-items: center;
    color: var(--display-onlight-tertiary);
    border-radius: var(--border-radius-sm);

    &.hovered {
        background-color: rgb(250 237 230);
    }
}

.profileMenuAvatar {
    display: grid;
    inline-size: 26px;
    block-size: 26px;
    place-items: center;

    & > * {
        grid-column: 1;
        grid-row: 1;
    }

    svg {
        transform-origin: center;
        transform: rotate(-90deg);
    }
}

.menuChevron {
    .hovered & {
        color: var(--display-onlight-primary);
    }
}

.progressBackground {
    fill: none;
    stroke: var(--selectable-unselected-background-idle);
    stroke-width: 2px;
}

.progressArc {
    fill: none;
    stroke: var(--productui-primary-theme-color);
    stroke-width: 2px;
    stroke-linecap: round;
}

.profileIcons {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--display-onlight-tertiary);
    block-size: var(--space-24);

    > div {
        position: relative;
        > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
        inline-size: var(--space-24);
        block-size: var(--space-24);
    }
}
